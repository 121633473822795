<template>
  <div class="products">
    <CategoryTitle :category="category" :showDescription="true" />
    <v-row>
      <v-col
        cols="12"
        md="6"
        xl="4"
        v-for="news in newsList"
        :key="news.id"
        @click="openNews(news)"
      >
        <news-card
          :key="news.newsId"
          :news="news"
          :show-opened="true"
          :show-read="false"
          @read="markAsRead(news.newsId)"
        />
      </v-col>
    </v-row>
    <v-pagination
      v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
      :value="page"
      :page="pager.selPage"
      :length="pager.totPages ? pager.totPages : 0"
      :totalVisible="6"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
    ></v-pagination>
    <NewsAlert :news="openedNews" v-if="openedNews != null" />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import NewsCard from "@/components/news/NewsCard.vue";
import NewsAlert from "@/components/news/NewsAlert.vue";
import category from "~/mixins/category";
import newsService from "~/service/newsService";

export default {
  name: "NewsListPage",
  mixins: [category],
  components: { CategoryTitle, NewsCard, NewsAlert },
  data() {
    return {
      newsList: [],
      openedNews: null,
      pager: {},
      page: 1
    };
  },
  computed: {
    showDialog() {
      return this.newsList.length > 0;
    }
  },
  methods: {
    async fetchNews() {
      this.newsList = await newsService.list();
      // this.newsList = [
      //   {
      //     newsId: "161",
      //     newsTypeId: 1,
      //     title: "Notifica 1",
      //     descr: "Descr 1",
      //     readDate: null,
      //     navTo: ""
      //   },
      //   {
      //     newsId: "162",
      //     newsTypeId: 1,
      //     title: "Notifica 2",
      //     descr: "Descr 2",
      //     readDate: null,
      //     navTo: ""
      //   },
      //   {
      //     newsId: 163,
      //     newsTypeId: 1,
      //     title: "Notifica 3",
      //     descr: "Descr 3",
      //     readDate: null,
      //     navTo: "orders"
      //   }
      // ];
    },
    openNews(id) {
      let clone = JSON.parse(JSON.stringify(id));
      this.openedNews = clone;
    },
    handlePageFilter(page) {
      var _this = this;
      // console.log(page);
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchNews(this.page, 24);
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  mounted() {
    this.fetchNews();
  }
};
</script>
